'use client'

import { useEffect } from 'react'

const PageImpressionTracking = ({ page }) => {
    useEffect(() => {
      // Initialize the digitalData object if it doesn't already exist
      window.digitalData = window.digitalData || {};
      window.digitalData.events = window.digitalData.events || [];

      // Push the new event
      window.digitalData.events.push({
        event: {
          schema: "iglu:be.vrt/page_impression/jsonschema/1-1-0",
          data: {
            timestamp: Date.now(),
          },
        },
        context: [
          {
            schema: "iglu:be.vrt/context_page/jsonschema/1-2-2",
            data: {
              pagename: `${window.location.pathname}/`.replace(/\/\/$/, "/"),
              touchpointbrand: "vrt corporate",
              contentbrand: "vrt",
              pageurl: window.location.href,
              pagetype: page.__typename,
              pagetitle: page.title,
              domain: window.location.hostname,
              pagepath: `${window.location.pathname}/`.replace(/\/\/$/, "/"),
              pagelanguage: "nl",
              pageid: `${window.location.pathname}/`.replace(/\/\/$/, "/"),
            },
          },
        ],
      });
    }, [page]);
    return null; // Render nothing
}

export default PageImpressionTracking;


